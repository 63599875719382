.navbar {
  z-index: 999;
  box-shadow: none;
  height: 85px !important; 
  transition: all 0.3s ease-in;
  top: 0; /* Initially, set the top position to 0 */
  background: transparent !important;
  /* background: #001650 !important; */
  /* font-family: Poppins; */
}  
@media only screen and (min-width: 992px) {
  .mobile-AccessibilityMenu-show {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .desktop-AccessibilityMenu-show {
    display: none;
  }
  .mobile-AccessibilityMenu-show {
    position: absolute;
    right: 4.5rem;
    top: 1rem;
    z-index: 2;
  }
}
@media only screen and (min-width: 320px) and (max-width: 349.7px) {
  .mobile-AccessibilityMenu-show {
    position: absolute;
    right: 3.7rem!important;
    top: 1rem;
    z-index: 2;
  }
}
.navbar.active {
  background: #001650 !important;
}   
.navbar-dark .navbar-nav {
  justify-content: space-around;
  align-items: center;
}  
.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
 
}  

.nav-link:hover
 {
  transform: scale(1.1) !important;
}

.navbar-collapse {
  background-color: transparent;
}  
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.8rem;
  line-height: 1;
  background-color: transparent;
  border: 0px solid transparent;
  border-radius: 0rem;
  fill: #ffb600;
}  
.navbar-pricing-btn{
  padding-top: 0.5rem;
}
@media only screen and (max-width: 5120.98px) {
  .navbar-dark .navbar-nav .nav-link {
    margin: 0rem 0 0rem 1.5rem;
  }
}  
@media only screen and (max-width: 599px) {
  .navbar-collapse{
      width: 100% !important;
  }
} 

@media only screen and (max-width: 350px) {
.main_logo{
  width: 170px !important;
}
.navbar-toggler-icon{
  width: 25px;
}
}   

@media only screen and (min-width: 1200px) and (max-width: 1399.97px) {
  .navbar-dark .navbar-nav .nav-link {
    margin: 0rem 0 0rem 5px;
    font-size: 17px;
  }
}
@media only screen and (min-width: 991.97px) and (max-width: 1199.97px) {
  .navbar {
    width: 100%;
    transition: all 0.5s ease-in;
  }  
.nav-link:hover
  {
    transform: scale(1) !important;
  }
  .navbar-dark .navbar-nav .nav-link {
    margin: 0rem 0 0rem 18px;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
  }
  .main_logo{
    width: 180px !important;
  }
  .navbar-nav .ms-left {
    padding-right: 5rem !important;
  }
  .ask-angel-logo{
    align-items: center;
  }
}


@media only screen and (max-width: 991.97px) {
.navbar{
  margin-top: -20px !important;
  height: 80px !important;
}

.navbar-container{
  margin-top: 14px;
}
 
.navbarTransition {
  transform: translateY(-800%);
  transition: all .7s ease-in;
}
.main_logo{
  width: 180px;
} 

  /* Define the CSS class for sliding in the navbar */
  .slide-in {
    animation: slideIn 0.5s ease-in ;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

      .bg-light {
    background-color: transparent !important;
  }

  .navbar-collapse {
    padding: 1rem 1rem 1rem;
    text-align: left;
    background-color: #ffffff;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    top: 100%;
    margin-top: 2px;
    border-radius: 5px;
    background: #072449;
     }

  .navbar-dark .navbar-nav .nav-link {
    color: #fff;
  }
 
   
  .navbar-dark .navbar-nav .nav-link {
    margin: 0;
    font-size: 1rem !important;
  }

  .navbar .btn-outline-warning {
    margin-top: 0.5rem;
  }
} 

@media screen and (min-width: 992px) {
 
  .main_logo {
    width: 215px;
    margin-left: auto !important;
  }
  .navbar-dark .navbar-nav .nav-link {
    font-size: 17px;
  }
    
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: flex-end;
  }
  }

@media only screen and (min-width: 320px) and (max-width: 358.7px) {
 
  .main_logo{     
    margin-top: 4px;
  }
}

.login_modal {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  width: 90%;
  max-width: 500px; /* Adjust the max width as needed */
  padding: 5px;
  border-radius: 10px;
}

.login_modal_content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.close_button {
position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 18px;
  color: #000;
}

.login_modal_context {
  margin-bottom: 20px;
}

.login_modal_buttons {
  display: flex;
  justify-content: space-between;
}

.No_btn,
.Yes_btn {
  width: 45%;
}

.contact-us-btn{
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 10px;
}

/* Modal styles */
.form-modal {
  position: relative;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close-modal-button {
  position: absolute;
    top: -5px !important; 
    right: -5px !important; 
    background: orange;
    border: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    border-radius: 50%;
    padding: 3px 13px; 
    z-index: 1001 !important;
}

.close-modal-button:hover {
  color: #333;
  background: rgb(255, 141, 47);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid #dee2e6;
}

.modal-body {
  padding: 10px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px;
  border-top: 1px solid #dee2e6;
}

@media (max-width: 768px) {
  .form-modal {
      padding: 10px;
  }
  
  .close-modal-button {
      font-size: 20px;
  }
}
