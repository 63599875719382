.banner{
  background-image: url("https://staging-cdn.swmc.com/angelai-life/banner-bg-desktop.webp");
  background-size: 100% 100%;
  padding-bottom: 38rem;
  padding-top: 6rem;
}

.banner-subpage{
  background-image: url("https://staging-cdn.swmc.com/angelai-life/banner-bg-desktop.webp");
  background-size: 100% 100%;
  padding-bottom: 15rem;
  padding-top: 6rem;
}

.banner h1{
   color: #FF541C;
   text-align: center;
}

.banner-span{
  color: #ffffff;
}

.nothingText{
  text-align: center;
}
.fair-span{
  color: #FF541C;
}


.brandTagLine {
  color: #fff;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  text-align: center;
  font-size: 18px !important;
}



@media screen and (max-width: 991.7px){
  .banner-para{
    text-align: justify;
    color: #ffffff;
    opacity: 0.9;
    border: none;
    padding: 0px 5px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .banner{
    padding-bottom: 59rem;
    padding-top: 6.5rem;
  }
  .banner-subpage{
    padding-bottom: 7rem;
    padding-top: 6rem;
  }
  .navbar-links-mobile{
    display: flex;  
    flex-direction:row;
    text-align: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .navbar-links-mobile div{
    color: #ffffff;
    list-style: none;
    /* margin: 0px 10px; */
    font-size: 15px
  }
  .link-span{
   margin-left: 10px;
   margin-right: 10px;
  }
  .navbar-links-mobile a {
    color: #ffffff !important;
    text-decoration: none;
}
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .banner{
    padding-bottom: 45rem;
    
  }
}

@media screen and (min-width: 992px) {
  .navbar-links-mobile{
    display: none;
  }
  .banner-para{
    text-align: center;
    color: #ffffff;
    opacity: 0.9;
    border: 1px dotted #ffffff;
    padding: 15px 40px;
    border-radius: 20px;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .banner{
    padding-bottom: 32rem !important;
  }
 
}

@media screen and (min-width: 320px) and (max-width: 350px) {
  .banner{
    padding-bottom: 70rem !important;
  }
 
}

@media screen and (min-width: 400px) and (max-width: 991px) {
  .navbar-links-mobile div{
    font-size: 17px
  }
}

@media screen and (min-width: 1400px) {
  .banner-para{
    border: 2px dotted #ffffff;
  }
}

@media  screen and (min-width: 992px) and (max-width: 1200px) {
  .banner-subpage{
    padding-bottom: 10rem;
    padding-top: 6rem;
  }
}

@media screen and (min-width:2400px){
  .banner-subpage{
 padding-bottom: 25rem;
  }
  .banner{
    padding-top: 11rem!important;
  }
  .bannercontainer{
    padding-bottom: 2rem;
  }
  .banner-para{
   margin-top: 3rem; 
  }
  #resources-wrapper{
    padding-top: 2rem!important;
  }
}